









































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { EmailNotificationTypeDocument } from '@/api/enums/emailNotificationTypeDocument'
import { EmailNotificationType } from '@/api/enums/emailNotificationType'
import Mustache from '@/plugins/mustache'
import { NotificationEmailAttestationIndividual } from '@/enums/notificationEmailAttestationIndividual'
import { NotificationEmailConvocationIndividual } from '@/enums/notificationEmailConvocationIndividual'
import { format, parseJSON } from 'date-fns'
import Loading from '@/components/base/Loading.vue'

export default defineComponent({
  name: 'SendSessionDocumentDialog',
  components: { Loading },
  props: {
    title: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    logisticSession: {
      type: Object,
      required: true,
    },
    trainingSession: {
      type: Object,
      required: true,
    },
    needTraining: {
      type: Object,
      required: true,
    },
    typeDocument: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isSubmiting: false,
    isLoadingNotificationEmail: false,
    object: '',
    content: '',
    itemsCC: [] as Array<any>,
    itemsCCI: [] as Array<any>,
    isMentionClimate: false,
    emailSender: '',
  }),
  watch: {
    needTraining () {
      this.init()
    },
    typeDocument (newValue, oldValue) {
      if (oldValue === null || oldValue === '' || oldValue === undefined) {
        return
      }
      this.init()
    },
  },
  methods: {
    ...mapActions('emailSessionDocument', {
      saveEmailSessionDocument: 'save',
    }),
    ...mapActions('configuration', {
      getEmailSenderByNeedTraining: 'getEmailSenderByNeedTraining',
    }),
    ...mapActions('notificationEmail', {
      getNotificationEmailByNeedTraining: 'getNotificationEmailByNeedTraining',
    }),
    async init () {
      this.isLoadingNotificationEmail = true
      if (Object.keys(this.needTraining).length === 0) {
        return
      }
      this.isMentionClimate = false
      this.itemsCC = this.getItemsCC()
      this.itemsCCI = this.getItemsCCI()
      this.emailSender = await this.getEmailSenderByNeedTraining(this.needTraining)
      const notificationEmail = await this.getNotificationEmailByNeedTraining({
        needTraining: this.needTraining,
        emailNotificationTypeDocument: this.typeDocument,
        emailNotificationType: EmailNotificationType.INDIVIDUAL,
      })
      if (notificationEmail) {
        if (this.isDocumentAttestation) {
          this.object = Mustache.render(notificationEmail.object, {
            [NotificationEmailAttestationIndividual.TRAINEE_LAST_NAME]: this.needTraining.trainee.lastName,
            [NotificationEmailAttestationIndividual.TRAINEE_FIRST_NAME]: this.needTraining.trainee.firstName,
            [NotificationEmailAttestationIndividual.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
          })
          this.content = Mustache.render(notificationEmail.content, {
            [NotificationEmailAttestationIndividual.TRAINEE_LAST_NAME]: this.needTraining.trainee.lastName,
            [NotificationEmailAttestationIndividual.TRAINEE_FIRST_NAME]: this.needTraining.trainee.firstName,
            [NotificationEmailAttestationIndividual.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
          })
        } else if (this.isDocumentConvocation) {
          const trainingSessionStartAt = format(parseJSON(this.trainingSession.startAt), 'dd/MM/yyyy')
          this.object = Mustache.render(notificationEmail.object, {
            [NotificationEmailConvocationIndividual.TRAINEE_LAST_NAME]: this.needTraining.trainee.lastName,
            [NotificationEmailConvocationIndividual.TRAINEE_FIRST_NAME]: this.needTraining.trainee.firstName,
            [NotificationEmailConvocationIndividual.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
            [NotificationEmailConvocationIndividual.TRAINING_LOCATION_ADDRESS]: this.trainingSession.trainingLocation.address,
            [NotificationEmailConvocationIndividual.TRAINING_LOCATION_CITY]: this.trainingSession.trainingLocation.city,
            [NotificationEmailConvocationIndividual.TRAINING_LOCATION_ZIP_CODE]: this.trainingSession.trainingLocation.zipCode,
            [NotificationEmailConvocationIndividual.TRAINING_SESSION_DATE]: function () { return trainingSessionStartAt },
          })
          this.content = Mustache.render(notificationEmail.content, {
            [NotificationEmailConvocationIndividual.TRAINEE_LAST_NAME]: this.needTraining.trainee.lastName,
            [NotificationEmailConvocationIndividual.TRAINEE_FIRST_NAME]: this.needTraining.trainee.firstName,
            [NotificationEmailConvocationIndividual.TRAINING_TYPE_NAME]: this.trainingSession.trainingType.name,
            [NotificationEmailConvocationIndividual.TRAINING_LOCATION_ADDRESS]: this.trainingSession.trainingLocation.address,
            [NotificationEmailConvocationIndividual.TRAINING_LOCATION_CITY]: this.trainingSession.trainingLocation.city,
            [NotificationEmailConvocationIndividual.TRAINING_LOCATION_ZIP_CODE]: this.trainingSession.trainingLocation.zipCode,
            [NotificationEmailConvocationIndividual.TRAINING_SESSION_DATE]: function () { return trainingSessionStartAt },
            [NotificationEmailConvocationIndividual.COMPANY_NAME]: this.needTraining.company.name,
          })
        }
      }
      this.isLoadingNotificationEmail = false
    },
    onClose () {
      this.$emit('close')
    },
    addItemsCC (item: any) {
      this.itemsCC.push(item)
    },
    deleteItemCC: function (index: any) {
      this.itemsCC.splice(index, 1)
      if (this.itemsCC.length === 0) {
        this.addItemsCC({ email: '' })
      }
    },
    getItemsCC () {
      const items: Array<any> = []

      if ((this.needTraining as NeedTraining)?.manager?.email) {
        items.push({ email: (this.needTraining as NeedTraining).manager.email })
      }

      return items
    },
    addItemsCCI (item: any) {
      this.itemsCCI.push(item)
    },
    deleteItemCCI: function (index: any) {
      this.itemsCCI.splice(index, 1)
      if (this.itemsCCI.length === 0) {
        this.addItemsCCI({ email: '' })
      }
    },
    getItemsCCI () {
      const items: Array<any> = []

      if ((this.needTraining as NeedTraining)?.carDealerSeller?.email) {
        items.push({ email: (this.needTraining as NeedTraining).carDealerSeller.email })
      }

      return items
    },
    async onSubmit () {
      try {
        this.isSubmiting = true
        const emailSessionDocument = {
          trainingSession: `/api/training_sessions/${(this.trainingSession as TrainingSession).id}`,
          needTraining: `/api/need_trainings/${(this.needTraining as TrainingSession).id}`,
          title: this.object,
          content: this.content,
          typeDocument: this.typeDocument,
          receiver: (this.needTraining as NeedTraining).trainee.email,
          carbonCopy: this.itemsCC,
          blindCarbonCopy: this.itemsCCI,
          isMentionClimate: this.isMentionClimate,
          emailNotificationType: EmailNotificationType.INDIVIDUAL,
          sender: this.emailSender,
        }
        await this.saveEmailSessionDocument(emailSessionDocument)
      } finally {
        this.isSubmiting = false
        this.$emit('close')
        this.$emit('reload')
      }
    },
  },
  computed: {
    isDocumentAttestation (): boolean {
      return this.typeDocument === EmailNotificationTypeDocument.ATTESTATION
    },
    isDocumentConvocation (): boolean {
      return this.typeDocument === EmailNotificationTypeDocument.CONVOCATION
    },
  },
})
